.navbar {
    width: 100%;
    display: flex;
    flex-direction: row;
    z-index: 1;
    border-top: 10px solid #EE3B3A;
    background: #f4f4f4;
    height: 7vh;
    padding: 0 5vw;
}

.navbar .logo {
    flex: 0.5;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    position: absolute;
    top: 0;
}

.navbar .logo img {
    width: 100%;
    max-width: 100px;
}

.navbar .menu-nav-desktop {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

.navbar .menu-nav-desktop ul {
    display: flex;
    flex-direction: row;
}

.navbar .menu-nav-desktop ul a {
    text-decoration: none;
    color: initial;
}

.navbar .menu-nav-desktop ul li {
    list-style: none;
    margin-right: 20px;
}

@media screen and (max-width: 425px) {
    .navbar .menu-nav-desktop {
        display: none;
    }
}